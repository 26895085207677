import React, { useState, useEffect, useMemo } from 'react';
import { INotification } from 'interfaces/notification.interface';
import { ICustomer } from 'interfaces/customer.interface';
import { api } from 'helpers/auth-axios';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { format, isToday, isThisWeek } from 'date-fns';
import { Link } from 'react-router-dom';
import { LoaderOverlay } from '@components/common';
import { useNotification } from '../../context/NotificationContext';

const NotificationList = () => {
    interface MarkAsReadResponse {
        updatedCount: number;
    }

    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [selectedClient, setSelectedClient] = useState<ICustomer | null>(null);
    const [loading, setLoading] = useState(true);
    const { t }: any = useTranslation();
    const [deleting, setDeleting] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [messagesPage, setMessagesPage] = useState(0);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const { decrementUnreadCount } = useNotification();

    const _user = JSON.parse(localStorage.getItem('user') || '{}');
    const userId = _user.user._id;
    const businessId = _user.user.business_id._id;
    const pageSize = 20;

    useEffect(() => {
        fetchClients(0);
    }, [businessId]);

    useEffect(() => {
        const markClientNotificationsAsRead = async (clientId: string) => {
            try {
                const response = await api.put<MarkAsReadResponse>(
                    `/notifications/client/${clientId}/mark-as-read`
                );
                const updatedCount = response.data.updatedCount;

                if (updatedCount > 0) {
                    setNotifications(prevNotifications =>
                        prevNotifications.map(notif =>
                            notif.customer_id === clientId
                                ? {
                                      ...notif,
                                      is_read: [
                                          ...notif.is_read,
                                          { user_id: userId, read_at: new Date() },
                                      ],
                                  }
                                : notif
                        )
                    );
                    decrementUnreadCount(updatedCount);
                }
            } catch (error) {
                console.error('Error marking notifications as read:', error);
            }
        };

        if (selectedClient) {
            if (selectedClient.hasUnread) {
                markClientNotificationsAsRead(selectedClient._id);
            }
            fetchMessagesByClient(selectedClient._id, 0);
        }
    }, [selectedClient]);

    useEffect(() => {
        if (selectedClient) {
            fetchMessagesByClient(selectedClient._id, 0);
        }
    }, [selectedClient]);

    const handleClientClick = (client: ICustomer) => {
        setSelectedClient(client);

        if (client.hasUnread) {
            setCustomers(prev =>
                prev.map(c => (c._id === client._id ? { ...c, hasUnread: false } : c))
            );
        }
    };

    const handleBackToClients = () => {
        setSelectedClient(null);
    };

    const fetchClients = async (page: number) => {
        try {
            setLoading(true);
            const response = await api.get<ICustomer[]>(`/notifications/customers`, {
                params: { page, pageSize },
            });

            if (Array.isArray(response.data)) {
                if (response.data.length < pageSize) {
                    setHasMore(false);
                }
                setCustomers(prev => [...prev, ...response.data]);
            } else {
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMessagesByClient = async (clientId: string, page: number) => {
        try {
            setLoading(true);
            const response = await api.get<INotification[]>(`/notifications/messages/${clientId}`, {
                params: { limit: 20, skip: page * 20 },
            });
            if (response.data.length < 20) {
                setHasMoreMessages(false);
            }
            setNotifications(prev => (page === 0 ? response.data : [...prev, ...response.data]));
        } catch (error) {
            console.error('Error fetching messages for client:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadMoreClients = () => {
        if (hasMore) {
            fetchClients(page + 1);
            setPage(prev => prev + 1);
        }
    };

    const loadMoreMessages = () => {
        if (selectedClient && hasMoreMessages) {
            fetchMessagesByClient(selectedClient._id, messagesPage + 1);
            setMessagesPage(prev => prev + 1);
        }
    };

    const clientList = useMemo(() => {
        return customers.map(customer => {
            const { lastMessage, hasUnread } = customer;
            const event = lastMessage?.event;
            const emoji = event === 'create' ? '✅' : event === 'cancel' ? '❌' : '';
            const translatedMessage = lastMessage
                ? `${emoji} ${t(lastMessage.content?.title || 'No Notification')} — ${
                      lastMessage.content?.data?.serviceName || t('No Service Name')
                  } - ${lastMessage.content?.data?.date || t('No Date')}`
                : t('No messages');

            return {
                ...customer,
                lastMessage: translatedMessage,
                lastMessageTime: lastMessage?.created_at || '',
                unreadCount: hasUnread ? 1 : 0,
            };
        });
    }, [customers, t]);

    const handleDeleteMessage = async (messageId: string) => {
        try {
            const response = await api.put(`/notifications/${messageId}/delete`, {
                user_id: userId,
            });
            if (response.status === 200) {
                setNotifications(prevNotifications =>
                    prevNotifications.filter(notif => notif._id !== messageId)
                );
                console.log('Updated notifications after delete:', notifications);
            } else {
                console.warn('Message delete failed:', response.data);
            }
        } catch (error) {
            console.error('Error deleting message:', error);
        }
    };

    const handleDeleteAllByCustomer = async (customerId: string) => {
        if (deleting) {
            return;
        }
        setDeleting(true);
        try {
            const response = (await api.put(`/notifications/customer/${customerId}/delete`, {
                user_id: userId,
            })) as { data: { updatedCount: number } };

            if (response.data.updatedCount > 0) {
                setCustomers(prevCustomers =>
                    prevCustomers.filter(customer => customer._id !== customerId)
                );

                setNotifications(prevNotifications =>
                    prevNotifications.filter(
                        notif =>
                            notif.customer_id !== customerId ||
                            notif.is_deleted?.some(entry => entry.user_id !== userId)
                    )
                );
            }
        } catch (error) {
        } finally {
            setDeleting(false);
        }
    };

    const renderDate = (date: Date) => {
        try {
            const dayOfWeek = format(date, 'EEEE');
            if (isToday(date)) {
                return format(date, 'HH:mm');
            } else if (isThisWeek(date)) {
                const translatedDay = t(`${dayOfWeek}`);
                return translatedDay || dayOfWeek;
            } else {
                return format(date, 'dd.MM.yyyy');
            }
        } catch (error) {
            return format(date, 'dd.MM.yyyy');
        }
    };

    interface UpdateResponse {
        updatedCount: number;
    }

    const handleMarkAllAsRead = async () => {
        try {
            const response = await api.put<UpdateResponse>(`/notifications/mark-all-as-read`);
            if (response.data.updatedCount > 0) {
                setCustomers(prev => prev.map(c => ({ ...c, hasUnread: false })));
                decrementUnreadCount(response.data.updatedCount);
            }
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    const handleDeleteAll = async () => {
        try {
            const response = await api.put<UpdateResponse>(`/notifications/delete-all`);
            if (response.data.updatedCount > 0) {
                setCustomers([]);
                setNotifications([]);
            }
        } catch (error) {
            console.error('Error deleting all notifications:', error);
        }
    };

    return (
        <Wrapper>
            {!selectedClient ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '15px',
                        }}>
                        <BackButton onClick={handleMarkAllAsRead}>
                            ✅ {t('Mark all as read')}
                        </BackButton>
                        <DeleteButton onClick={handleDeleteAll}>🗑️ {t('Delete all')}</DeleteButton>
                    </div>
                    <ClientList>
                        {clientList.length === 0 && !loading && (
                            <NoClients>{t('No clients with notifications')}</NoClients>
                        )}
                        {clientList.map(client => (
                            <ClientItem key={client._id}>
                                <AvatarWrapper>
                                    <Avatar
                                        src={`${process.env.REACT_APP_PROFILE_URL}${client.photo}`}
                                        alt={client.firstname}
                                    />
                                    {client.unreadCount > 0 && <UnreadDot />}
                                </AvatarWrapper>
                                <Details onClick={() => handleClientClick(client)}>
                                    <Header>
                                        <Name>{`${client.firstname} ${client.lastname}`}</Name>
                                        <TimeAndDeleteWrapper>
                                            <Time>
                                                {renderDate(new Date(client.lastMessageTime))}
                                            </Time>
                                            <DeleteButton
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleDeleteAllByCustomer(client._id);
                                                }}>
                                                🗑
                                            </DeleteButton>
                                        </TimeAndDeleteWrapper>
                                    </Header>
                                    <LastMessage isUnread={client.unreadCount > 0}>
                                        {client.lastMessage}
                                    </LastMessage>
                                </Details>
                            </ClientItem>
                        ))}
                        {loading && <LoaderOverlay />}
                        {hasMore && !loading && (
                            <LoadMore onClick={loadMoreClients}>{t('Load More')}</LoadMore>
                        )}
                    </ClientList>
                </>
            ) : (
                <MessagesWrapper>
                    <HeaderWrapper>
                        <BackButton onClick={handleBackToClients}>{t('Back')}</BackButton>
                        <Title>{`${selectedClient.firstname} ${selectedClient.lastname}`}</Title>
                    </HeaderWrapper>
                    <MessageList>
                        {notifications.map(message => {
                            const linkTo =
                                _user.user?.user_login === 'customer'
                                    ? `/${_user?.user?.business_id?._id}/${message._id}/appointment`
                                    : `/calendar?appointment=${message.appointment_id}`;

                            return (
                                <MessageItem
                                    key={message._id}
                                    isRead={
                                        Array.isArray(message.is_read) &&
                                        message.is_read.some(entry => entry.user_id === userId)
                                    }>
                                    <MessageHeader>
                                        <MessageTime>
                                            {renderDate(new Date(message.created_at))}
                                        </MessageTime>
                                        <DeleteButton
                                            onClick={() => handleDeleteMessage(message._id)}>
                                            🗑️
                                        </DeleteButton>
                                    </MessageHeader>
                                    <MessageContentWrapper>
                                        <MessageContent>
                                            {message.event === 'create'
                                                ? '✅'
                                                : message.event === 'cancel'
                                                ? '❌'
                                                : ''}{' '}
                                            <CustomLink to={linkTo}>
                                                {t(message.content?.title || t('No Notification'))}
                                            </CustomLink>{' '}
                                            — {message.content?.data?.serviceName} -{' '}
                                            {message.content?.data?.date}
                                        </MessageContent>
                                    </MessageContentWrapper>
                                </MessageItem>
                            );
                        })}
                        {loading && <LoaderOverlay />}
                        {hasMoreMessages && !loading && (
                            <LoadMore onClick={loadMoreMessages}>{t('Load More')}</LoadMore>
                        )}
                    </MessageList>
                </MessagesWrapper>
            )}
        </Wrapper>
    );
};

export default NotificationList;

const MessageItem = styled.div<{ isRead: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    background: ${({ isRead }) => (isRead ? '#f9f9f9' : '#fff')}; /* Светлый фон для прочитанных */
    border: 1px solid ${({ isRead }) => (isRead ? '#e0e0e0' : '#007bff')}; /* Синий бордер для непрочитанных */
    border-radius: 8px;
`;

const UnreadDot = styled.span`
    position: absolute;
    top: -5px;
    right: -5px;
    width: 12px;
    height: 12px;
    background-color: #007bff; /* Голубой цвет */
    border-radius: 50%;
    border: 2px solid white; /* Граница для контраста */
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5); /* Лёгкое свечение */
`;

const LoadMore = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;

const MessageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;

const MessageContentWrapper = styled.div`
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    display: flex;
    flex-direction: column;

    a {
        color: #007bff;
        font-weight: bold;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const DeleteButton = styled.button`
    background: transparent;
    border: none;
    color: #ff4d4f;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.2s ease;

    &:hover {
        color: #d11a2a;
    }
`;

const MessageTime = styled.span`
    font-size: 14px;
    color: #999;
    align-self: flex-end;
`;

const MessageContent = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #555;

    & > a {
        color: #007bff;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const CustomLink = styled(Link)`
    color: #007bff !important; /* Синий цвет для всех ссылок */
    font-weight: bold; /* Жирный текст для выделения */
    text-decoration: none; /* Убираем подчёркивание по умолчанию */

    &:hover {
        color: #0056b3 !important; /* Более тёмный синий при наведении */
        text-decoration: underline; /* Подчёркивание при наведении */
    }
`;

const TimeAndDeleteWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px; /* Отступ между временем и кнопкой удаления */
`;

const Name = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #333;
`;

const Time = styled.div`
    font-size: 12px;
    color: #999;
`;

const LastMessage = styled.div<{ isUnread: boolean }>`
    font-size: 14px;
    color: #666;
    font-weight: ${({ isUnread }) => (isUnread ? 'bold' : 'normal')};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Ограничение на две строки */
    -webkit-box-orient: vertical;
    margin-top: 5px; /* Небольшой отступ сверху */
`;

const AvatarWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Avatar = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
`;

const UnreadBadge = styled.span`
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
`;

const Wrapper = styled.div`
    padding: 20px;
    background-color: #f9f9f9;
`;

const ClientList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const ClientItem = styled.div`
    display: flex;
    align-items: flex-start; /* Выравниваем элементы по верхнему краю */
    gap: 15px;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Details = styled.div`
    flex-grow: 1;
    cursor: pointer;
`;

const MessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
    background: #fff;
    border-bottom: 1px solid #eee;
`;

const BackButton = styled.button`
    padding: 5px 10px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #333;
`;

const MessageList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
`;

const NoClients = styled.div`
    text-align: center;
    font-size: 16px;
    color: #888;
`;
