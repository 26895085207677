import { api } from 'helpers/auth-axios';

/**
 * Преобразует VAPID ключ из Base64 в Uint8Array
 */
function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    return new Uint8Array([...window.atob(base64)].map(char => char.charCodeAt(0)));
}

/**
 * Регистрирует Service Worker и подписывает пользователя на пуш-уведомления
 */
async function registerServiceWorker() {
    console.log('📲 registerServiceWorker вызван');
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
        console.warn('Push-уведомления не поддерживаются этим браузером.');
        return;
    }

    try {
        const registration = await navigator.serviceWorker.register('/sw.js');
        await subscribeUserToPush(registration);
    } catch (error) {
        console.error('❌ Ошибка регистрации Service Worker:', error);
    }
}

/**
 * Запрашивает разрешение и подписывает пользователя
 */
async function subscribeUserToPush(registration: ServiceWorkerRegistration) {
    try {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
            console.warn('❌ Пользователь отклонил пуш-уведомления');
            return;
        }
        await registerPush(registration);
    } catch (error) {
        console.error('❌ Ошибка при запросе разрешения на пуш-уведомления:', error);
    }
}

/**
 * Подписывает пользователя и отправляет подписку на сервер
 */
async function registerPush(registration: ServiceWorkerRegistration) {
    try {
        const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC;
        if (!vapidPublicKey) {
            throw new Error('VAPID Public Key не найден! Проверь .env файл.');
        }

        const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey,
        });

        const response = await api.post('/notifications/save-subscription', subscription);
        if (![200, 201].includes(response.status)) {
            throw new Error(`Ошибка отправки подписки: ${response.status}`);
        }
    } catch (error) {
        console.error('❌ Ошибка подписки на пуши:', error);
    }
}

async function unsubscribeFromPush() {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) return;

    const subscription = await registration.pushManager.getSubscription();
    if (subscription) {
        await subscription.unsubscribe();
        console.log('🔕 Подписка отменена');

        // Можно также уведомить сервер
        try {
            await api.post('/notifications/unsubscribe', {
                endpoint: subscription.endpoint,
            });
        } catch (err) {
            console.warn('⚠️ Ошибка при отправке отписки на сервер:', err);
        }
    } else {
        console.log('🔕 Пользователь не был подписан');
    }
}

export { registerServiceWorker, unsubscribeFromPush };
